export const useTempGameStore = defineStore('tempGame', () => {
  const tempGameId = useCookie<string>('temp-game:id');
  const tempGame = ref<any>();

  const api = useApi();
  const events = ref<any[]>([]);
  const initialized = ref(false);

  async function init(gameId?: string) {
    if (initialized.value) return;
    try {
      gameId = gameId || tempGameId.value;

      if (!gameId) {
        await createNewGame();
      } else {
        await retrieveOrCreateGame(gameId);
      }

      if (tempGame?.value?.id) {
        startListeningToGame(tempGame.value.id);
      }
    } catch (error) {
      console.error("Error initializing game:", error);
    } finally {
      initialized.value = true;
    }
  }

  async function createNewGame() {
    const gameData = await api.v1.tempGame.create();
    if (gameData && gameData.id) {
      tempGame.value = gameData;
      tempGameId.value = gameData.id;
    } else {
      console.error('Failed to create new game:', gameData);
    }
  }

  async function retrieveOrCreateGame(gameId: string) {
    try {
      const gameData = await api.v1.tempGame.retrieve(gameId);
      if (gameData && gameData.id) {
        tempGame.value = gameData;
        tempGameId.value = gameData.id;
      } else {
        console.warn('Game not found or invalid:', gameData);
        await createNewGame();
      }
    } catch (error) {
      console.error('Error retrieving game:', error);
      await createNewGame();
    }
  }

  const startListeningToGame = (gameId: string) => {
    return api.v1.tempGame.subscribeToScoresForTempGameChannel(gameId, async () => {
      events.value.push({ gameId, timestamp: new Date() });
      const newData = await api.v1.tempGame.retrieve(gameId)
      tempGame.value = newData;
    })
  }

  const hasGame = computed(() => !!tempGame?.value?.id);

  const incrementGame = (teamIndex: number) => {
    api.v1.tempGame.increment(tempGame.value.id, teamIndex);
  }

  const decrementGame = (teamIndex: number) => {
    api.v1.tempGame.decrement(tempGame.value.id, teamIndex);
  }

  async function startGame() {
    await api.v1.tempGame.start(tempGame.value.id);

    if (tempGame.value && tempGame.value.data && tempGame.value.data.game_clock) {
      tempGame.value.data.game_clock.is_running = true;
      tempGame.value.data.game_clock.started_at = new Date().toISOString();
    }
  }

  async function playGame() {
    await api.v1.tempGame.play(tempGame.value.id);

    if (tempGame.value && tempGame.value.data && tempGame.value.data.game_clock) {
      tempGame.value.data.game_clock.is_running = true;
      tempGame.value.data.game_clock.started_at = new Date().toISOString();
    }
  }

  async function pauseGame(remainingTime: { remaining_minutes: number; remaining_seconds: number }) {
    await api.v1.tempGame.pause(tempGame.value.id, remainingTime);

    // Update the local game clock with the new remaining time
    if (tempGame.value && tempGame.value.data && tempGame.value.data.game_clock) {
      tempGame.value.data.game_clock.is_running = false;
      tempGame.value.data.game_clock.remaining_minutes = remainingTime.remaining_minutes;
      tempGame.value.data.game_clock.remaining_seconds = remainingTime.remaining_seconds;
    }
  }

  return {
    hasGame,
    events,
    init,
    tempGame,
    incrementGame,
    decrementGame,
    startGame,
    playGame,
    pauseGame,
  }
});